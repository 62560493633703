<template>
  <div>
    <ManageScholarshipsPhD />
  </div>
</template>
<script>
import ManageScholarshipsPhD from "@/components/ManageScholarshipsPhD/ManageScholarshipsPhD";
export default {
  components: {
    ManageScholarshipsPhD,
  },
  created() {},
};
</script>
